
import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'

const GET_TABLE_OF_CONTENTS = graphql`
  {
    allTocJson {
      edges {
        node {
          emoji
          id
          sequenceNumber
          title
          url
          content {
            depth
            chapterId
            id
            title
            url
          }
        }
      }
    }
  }
`

const TableOfContents = () => {
  const { allTocJson } = useStaticQuery(GET_TABLE_OF_CONTENTS);
  const toc = allTocJson.edges.map((e) => ({ ...e.node }))
    .filter((toc) => toc.sequenceNumber !== 0);
    
  return (
    <section className="toc">
      <div className="page">
        <h1>solidbook | Introduction to Software Design and Architecture with TypeScript</h1>
        <h2>Table of contents</h2>
        <p>This book is still being written ✍️ but you can read everything currently listed here. You can <Link to="/#pricing">buy the preorder 📕</Link>, <a href="mailto:khalil@khalilstemmler.com">contact me</a> and suggest a topic to address, or check back later for more details!</p>

        {toc.map((content, i) => (
          <div className="chapter" key={i}>
            <h3>{content.emoji} {content.title}</h3>
            {content.content
              .filter((c) => c.depth === 1 || c.depth === 2)
              .map((c) => (
                <p className={`depth-${c.depth}`}>{c.title}</p>
              ))}
          </div>
        ))}

        <p className="cta">Convinced? <Link to="/#pricing">Get the book now 📕</Link></p>
        
      </div>
    </section>
  )
}

export default TableOfContents;